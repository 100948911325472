
.report-content {
    padding: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .main-content {
        flex: 1;
        height: 100%;
    }

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }

    ::v-deep .el-scrollbar__view {
        padding: 0 20px;
        height: calc(100% - 20px);
        display: flex;
        flex-direction: column;
    }

    .top-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &:after {
            position: absolute;
            content: '';
            background: #F1F5FF;
            height: 2px;
            width: 100%;
            bottom: -16px;
        }
    }

    .search-box {
        padding-top: 32px;

        ::v-deep .el-input {
            .el-input-group__append {
                padding-right: 10px;
                box-sizing: border-box;
                height: 40px;
                //background: #2DC079;
                color: #fff;
                font-size: 16px;

                .el-button {
                    height: 40px;
                    border-radius: 0 4px 4px 0;
                    padding: 0 8px;
                    background: #2DC079;

                    &:hover {
                        background: #2DC079;
                    }
                }
            }
        }
    }

    .customTable {
        width: 100%;
        flex: 1;
        border: 1px solid #EEEEEE;
        margin-top: 16px;
    }
}
